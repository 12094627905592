<script>
import axios from "axios";
/**
 * Earning Reports component
 */
export default {
  data() {
    return {
      weekly:null,
      monthly:null,
      weeklyEarning: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            wight: 60,
            height: 60,
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#5664d2"],
          stroke: {
            lineCap: "round"
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%"
              },
              track: {
                margin: 0
              },
              dataLabels: {
                show: false
              }
            }
          }
        }
      },
      monthlyEarning: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            wight: 60,
            height: 60,
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#1cbb8c"],
          stroke: {
            lineCap: "round"
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%"
              },
              track: {
                margin: 0
              },

              dataLabels: {
                show: false
              }
            }
          }
        }
      }
    };
  },
  computed:{
    monthlySeries(){
      let date=new Date()
      return [date.getDate()/30*100]
    },
    weeklySeries(){
      let date=new Date()
      return [(date.getDay())/7*100]
    }
  },

  mounted(){
    axios.get('get-earning-this-month',{
      headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    }).then((response)=>{
    
      this.monthly=response.data.response.totalRydelinxEarning
    }).catch((error)=>{
      console.log(error)
    })
    axios.get('get-earning-this-week',{
      headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    }).then((response)=>{
      
      this.weekly=response.data.response.totalRydelinxEarning
    }).catch((error)=>{
      console.log(error)
    })

    // let date = new Date()
    // this.monthlyEarning.series[0]=date.getDate()/30*100
    // this.weeklyEarning.series[0]=(date.getDay()+1)/7*100
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">

      <h4 class="card-title font-size-20 mb-4"><strong> Earning Reports</strong></h4>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-6">
            <div>
              <div class="mb-3">
                <apexchart
                  class="apex-charts"
                  height="60"
                  wight="60"
                  dir="ltr"
                  :series="weeklySeries"
                  :options="weeklyEarning.chartOptions"
                ></apexchart>
              </div>
              <p class="text-muted text-truncate mb-2">Weekly Earnings</p>
              <h5 class="mb-0">$ {{weeklyv  ? weekly:0 }}</h5>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mt-5 mt-sm-0">
              <div class="mb-3">
                <apexchart
                  class="apex-charts"
                  height="60"
                  wight="60"
                  dir="ltr"
                  :series="monthlySeries"
                  :options="monthlyEarning.chartOptions"
                ></apexchart>
              </div>
              <p class="text-muted text-truncate mb-2">Monthly Earnings</p>
              <h5 class="mb-0">$ {{monthly ? monthly :0}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>