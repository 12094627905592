<script>
/**
 * Widget Component
 */
import axios from "axios";



export default {

  
  mounted() {
    axios.get('number-of-all-rides',{
      headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    }).then((response) => {
      this.statData[0].value = response.data.response.allRides
    }).catch((err) => {
      console.log(err)
    })
    axios.get('get-earning',{
      headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    }).then((response) => {
      this.statData[1].value = response.data.response.totalTransactions
      this.statData[2].value = response.data.response.totalRydelinxEarning
    }).catch((error) => {
      console.log(error)
    })
  },
  data() {
    return {
      statData: [
        {
          title: "Number of Rides",
          icon: "ri-stack-line",
          value: null,
          subvalue: " 2.4% "
        },
        {
          title: "Total Revenue",
          icon: "ri-store-2-line",
          value: null,
          subvalue: " 2.4% "
        },
        {
          title: "Rydelinx Earning",
          icon: "ri-briefcase-4-line",
          value: null,
          subvalue: " 2.4% "
        }
      ]
    };
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <h4 class="text-truncate font-size-20 mb-2"><strong>{{ statData[0].title }}</strong></h4>
              <h4 class="mb-0">{{ statData[0].value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${statData[0].icon} font-size-24`"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <h4 class="text-truncate font-size-20 mb-2"><strong>{{ statData[1].title }}</strong></h4>
              <h4 class="mb-0">${{ statData[1].value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${statData[1].icon} font-size-24`"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <h4 class="text-truncate font-size-20 mb-2"><strong>{{ statData[2].title }}</strong></h4>
              <h4 class="mb-0">{{ statData[2].value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${statData[2].icon} font-size-24`"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- end row -->
</template>