<script>
import axios from "axios";

export default {
  // props:['rideByMonthData'],
  data() {
    return {
      series: [
        {
          name: "Standard Rides 2022",
          type: "column",
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
      ],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c","#5664d2","#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }
    };
  },

   mounted() {
  
     axios.get('number-of-rides-by-month',{params:{year:2023},
     headers: {
        'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('user')).token
      }}).then((response)=>{
      this.series=response.data.series
      
    }).catch((error)=>{
      console.log(error)
    })

  },


};
</script>

<template>

  <div class="card">
    <div class="card-body">
      <h4 class="card-title font-size-20 mb-4"><strong> Rides By Month Analytics</strong></h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart  ref="realTimeChart"
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <div class="col-sm-4">
          <div class="d-inline-flex">
            <h5 class="mr-2">$12,253</h5>
            <div class="text-success">
              <i class="mdi mdi-menu-up font-size-14"></i>2.2 %
            </div>
          </div>
          <p class="text-muted text-truncate mb-0">This month</p>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">$ 34,254</h5>
              <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"></i>2.1 %
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Previous Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0">$ 32,695</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>