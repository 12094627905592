<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "@/components/dashboard-components/widget";
import RideMyMonth from "@/components/dashboard-components/rideByMonth";
import RidesAnalytics from "@/components/dashboard-components/rides-analytics";
import EarningReport from "@/components/dashboard-components/earning";
import Transaction from '@/components/dashboard-components/transaction';
// import Sources from "@/components/dashboard-components/sources";
// import RecentActivity from "@/components/dashboard-components/recent-activity";
// import RevenueLocation from "@/components/dashboard-components/revenue-location";

export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RideMyMonth,
    RidesAnalytics,
    EarningReport,
    Transaction,
   
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Rydelinx"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],

      data: {
        standard2021Rides: [],
        standard2022Rides: [],
        premium2022Rides: [],
        premium2021Rides: [],
      }
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <Stat />
        <RideMyMonth />
      </div>
      <div class="col-xl-4">
        <RidesAnalytics />
        <EarningReport />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-4">
        <Sources />
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
      <div class="col-lg-4">
        <RevenueLocation />
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-12">
        <Transaction />
      </div>
    </div>
  </Layout>
</template>