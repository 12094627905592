<script>
import axios from "axios";

/**
 * Sales Analytics component
 */
export default {
  data() {
    return {
      series: [],
      standardPercentage:null,
      premiumPercentage:null,
      cancelPercentage:null,
      chartOptions: {
        chart: {
          height: 230,
          type: "donut"
        },
        labels: ["Hourly", "Premium", "Canceled"],
        plotOptions: {
          pie: {
            donut: {
              size: "75%"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902"]
      }
    };
  },
  mounted() {
    axios.get('number-of-all-rides',{
      headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
    }).then((response)=>{
     
      this.series.push(response.data.response.standardRides)
      this.series.push(response.data.response.luxuryRides)
      this.series.push(response.data.response.canceledRides)
      this.standardPercentage= Math.round((response.data.response.standardRides/response.data.response.allRides)*100)
      this.premiumPercentage= Math.round((response.data.response.luxuryRides/response.data.response.allRides)*100)
      this.cancelPercentage= Math.round((response.data.response.canceledRides/response.data.response.allRides)*100)
    }).catch((err)=>{
      console.log(err)
    })
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4 font-size-20"><strong>Rides Analytics</strong></h4>

      <div id="donut-chart" class="apex-charts"></div>
      <apexchart
        class="apex-charts"
        height="230"
        dir="ltr"
        :series="series"
        :options="chartOptions"
      ></apexchart>
      <div class="row">
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> Hourly
            </p>
            <h5>{{ standardPercentage }} %</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Premium
            </p>
            <h5>{{premiumPercentage}} %</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i> Canceled
            </p>
            <h5>{{cancelPercentage}} %</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>